<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col p-4">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-primary text-white">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                Coupon Code
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                Expiration
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                Users
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                Products
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
              >
                Variants
              </th>
              <th
                scope="col"
                class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium tracking-wider"
              ></th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="coupon in coupons" :key="coupon._id">
              <td class="p-4 whitespace-nowrap">
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900">
                    {{ coupon.name }}
                  </div>
                  <div class="text-sm text-gray-500">
                    <span v-if="coupon.unlimited"> &#8734; </span>
                    <span v-else>
                      {{ coupon.remaining }}
                    </span>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">
                  {{ coupon.code }}
                </div>
                <div class="text-sm text-gray-500">{{ coupon.department }}</div>
              </td>

              <td class="px-6 py-4 whitespace-nowrap">
                {{ getDaysTillExpiration(coupon.expiration) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div v-if="isArray(coupon.users)" class="text-green-400">
                  <font-awesome-icon icon="check" />
                </div>
                <div v-else class="text-red">
                  <font-awesome-icon icon="times" />
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div v-if="isArray(coupon.products)" class="text-green-400">
                  <font-awesome-icon icon="check" />
                </div>
                <div v-else class="text-red">
                  <font-awesome-icon icon="times" />
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div v-if="isArray(coupon.variants)" class="text-green-400">
                  <font-awesome-icon icon="check" />
                </div>
                <div v-else class="text-red">
                  <font-awesome-icon icon="times" />
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button @click="setCoupon(coupon)" class="text-blue-600 hover:text-blue-900">
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        @click="setCoupon({})"
        class="text-black bg-blue-200 rounded px-2 hover:text-blue-300"
      >
        New
      </button>
    </div>
    <ModalPopup v-if="coupon" @close="coupon = null" title="Edit Coupon (must be an admin to save)">
      <Coupon :coupon="coupon" @close="coupon = null" />
    </ModalPopup>
  </div>
</template>

<script>
import ModalPopup from '@/components/shared/DesktopModal.vue';
import Coupon from './Coupon';

export default {
  data: function () {
    return {
      coupon: null,
    };
  },
  props: {},
  computed: {
    coupons() {
      return this.$store.getters['admin/getCoupons'];
    },
  },
  watch: {},
  methods: {
    setCoupon(coupon) {
      this.coupon = coupon;
    },
    isArray(data) {
      if (data) {
        if (data.length > 0) {
          return true;
        }
      }
      return false;
    },
    getDaysTillExpiration(date) {
      const date1 = new Date(date);
      const today = new Date();
      const timeDifference = date1.getTime() - today.getTime();

      if (timeDifference < 0) {
        return 'Expired';
      } else {
        //calculate time difference
        return `${(timeDifference / (1000 * 60 * 60 * 24)).toFixed(0)} days`;
      }
    },
  },
  mounted() {
    this.$store.dispatch('variants/getVariants');
    this.$store.dispatch('products/getProducts', { type: 'normal' });
    this.$store.dispatch('admin/getCoupons');
  },
  components: {
    ModalPopup,
    Coupon,
  },
};
</script>
<style scoped></style>
