<template>
  <div class="overflow-y-auto max-h-3/4-screen">
    <div class="grid grid-cols-2 gap-x-6 gap-y-2">
      <div>
        <label for="name" class="text-sm font-medium text-gray-700">Name</label>
        <div class="mt-1 relative rounded-md">
          <input
            type="text"
            name="name"
            id="name"
            class="w-full pr-10 pl-2 border sm:text-sm rounded-md"
            :class="
              faultyInput(coupon.name)
                ? ' focus:ring-red text-red  focus:border-red border-red '
                : ''
            "
            v-model="coupon.name"
            aria-invalid="true"
            aria-describedby="email-error"
          />
          <div
            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red"
            v-if="faultyInput(coupon.name)"
          >
            <font-awesome-icon icon="exclamation" class="h-5 w-5" aria-hidden="true" />
          </div>
        </div>
        <p class="mt-1 text-sm text-red" v-if="faultyInput(coupon.name)">
          Name must be more than 4 characters.
        </p>
      </div>
      <div>
        <label for="type" class="text-sm font-medium text-gray-700">Type</label>
        <select
          id="type"
          name="type"
          v-model="coupon.type"
          class="mt-1 w-full pl-3 pr-10 py-2 text-base border focus:border-blue-500 sm:text-sm rounded-md"
        >
          <option :value="1">Percentage off product</option>
          <option :value="2">Percentage off variant</option>
          <option :value="5">Percentage off order</option>
          <option :value="3">Amount off order</option>
          <option :value="7">Product Variant Fixed Price</option>
          <option :value="4">Free Shipping</option>
          <option :value="8">Free Product Added to Order</option>
        </select>
      </div>
      <div>
        <label for="code" class="text-sm font-medium text-gray-700">Code</label>
        <div class="mt-1 relative">
          <input
            type="text"
            name="code"
            id="code"
            class="w-full pr-10 pl-2 border sm:text-sm rounded-md"
            :class="
              faultyInput(coupon.code)
                ? ' focus:ring-red text-red  focus:border-red border-red '
                : ''
            "
            v-model="coupon.code"
            aria-invalid="true"
            aria-describedby="email-error"
          />
          <div
            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red"
            v-if="faultyInput(coupon.code)"
          >
            <font-awesome-icon icon="exclamation" class="h-5 w-5" aria-hidden="true" />
          </div>
        </div>
        <p class="mt-1 text-sm text-red" v-if="faultyInput(coupon.code)">
          Code must be more than 4 characters.
        </p>
      </div>
      <div v-if="!coupon.type === 8">
        <label for="price" class="text-sm font-medium text-gray-700">
          <span v-if="coupon.type === 3 || coupon.type === 4 || coupon.type === 7"> Price </span>
          <span v-else>Percentage</span>
        </label>
        <div class="mt-1 relative rounded-md">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span
              v-if="coupon.type === 3 || coupon.type === 4 || coupon.type === 7"
              class="text-gray-500 sm:text-sm"
            >
              $
            </span>
          </div>

          <input
            type="text"
            name="price"
            id="price"
            class="w-full pr-12 sm:text-sm border rounded-md"
            placeholder="0"
            v-model="coupon.amount"
            aria-describedby="price-currency"
            :class="coupon.type === 3 || coupon.type === 4 || coupon.type === 7 ? 'pl-6' : ''"
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span
              class="text-gray-500 sm:text-sm"
              id="price-currency"
              v-if="coupon.type === 3 || coupon.type === 4 || coupon.type === 7"
            >
              USD
            </span>
            <span v-else class="text-gray-500 sm:text-sm">%</span>
          </div>
        </div>
      </div>
      <div v-if="coupon.type === 3 || coupon.type === 5 || coupon.type === 8">
        <label for="quota" class="text-sm font-medium text-gray-700">
          <span>Minimum price</span>
        </label>
        <div class="mt-1 relative rounded-md">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm"> $ </span>
          </div>
          <input
            autocomplete="new-password"
            type="text"
            name="price"
            id="quota"
            class="focus:border-blue-500 w-full pl-4 pr-12 sm:text-sm border rounded-md"
            placeholder="0"
            v-model="coupon.quota"
            aria-describedby="price-currency"
            style="padding-left: 25px"
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm" id="price-currency"> USD </span>
          </div>
        </div>
      </div>
      <div>
        <label for="date" class="block text-sm font-medium text-gray-700">Expiration Date</label>
        <div class="mt-1 relative rounded-md">
          <input
            autocomplete="new-password"
            type="date"
            name="date"
            id="date"
            v-model="coupon.expiration"
            class="focus:border-blue-500 w-full pl-2 sm:text-sm border rounded-md"
          />
        </div>
      </div>
      <div>
        <label for="remaining" class="block text-sm font-medium text-gray-700">
          Remaining coupons
        </label>
        <div class="mt-1 relative rounded-md">
          <input
            autocomplete="new-password"
            type="number"
            class="focus:border-blue-500 w-full pl-4 sm:text-sm border rounded-md"
            v-model="coupon.remaining"
            name=""
            id="remaining"
          />
        </div>
      </div>
      <div class="col-span-2 flex justify-between">
        <div>
          <label for="date" class="block text-sm font-medium text-gray-700">Status</label>
          <div class="mt-1 relative rounded-md">
            <ToggleButton
              :width="90"
              :value="coupon.active"
              :labels="{ checked: 'active', unchecked: 'inactive' }"
              @change="coupon.active = !coupon.active"
              :margin="3"
            ></ToggleButton>
          </div>
        </div>
        <div>
          <label for="date" class="block text-sm font-medium text-gray-700">Commercial</label>
          <div class="mt-1 relative rounded-md">
            <ToggleButton
              :width="60"
              :value="coupon.commercial"
              :labels="{ checked: 'yes', unchecked: 'no' }"
              @change="coupon.commercial = !coupon.commercial"
              :margin="3"
            ></ToggleButton>
          </div>
        </div>
        <div>
          <label for="date" class="block text-sm font-medium text-gray-700">Retail</label>
          <div class="mt-1 relative rounded-md">
            <ToggleButton
              :width="60"
              :value="coupon.retail"
              :labels="{ checked: 'yes', unchecked: 'no' }"
              @change="coupon.retail = !coupon.retail"
              :margin="3"
            ></ToggleButton>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-5 gap-4 mt-4">
      <!-- Users -->
      <div class="flex flex-col col-span-3">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-blue-200">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-bold uppercase tracking-wider"
                    >
                      Users
                    </th>

                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">
                        <font-awesome-icon icon="times" />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="coupon.users && coupon.users.length > 0" :key="userKey">
                  <tr
                    v-for="(person, personIdx) in coupon.users"
                    :key="person.email"
                    :class="personIdx % 2 === 0 ? 'bg-white' : 'bg-blue-200'"
                  >
                    <td
                      class="p-2 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center"
                    >
                      <div class="mr-2">
                        <img
                          v-if="person.avatar"
                          :src="person.avatar"
                          alt=""
                          class="flex-shrink-0 h-6 w-6 rounded-full"
                        />
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-shrink-0 h-6 w-6 rounded-full"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <div class="text-sm font-medium text-gray-900">
                          {{ person.name }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ person.email }}
                        </div>
                      </div>
                    </td>

                    <td
                      scope="col"
                      class="px-2 py-3 whitespace-nowrap text-right text-sm font-medium text-red"
                    >
                      <button
                        class="text-red hover:bg-red-200 rounded px-2 py-1"
                        @click="removeUser(person._id)"
                      >
                        <font-awesome-icon icon="times" />
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="p-2 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center"
                    >
                      No users selected
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2">
        <label class="block text-sm font-medium text-gray-700" id="userInput"> Add User </label>
        <div class="mt-1 relative">
          <CustomerSelect
            :loading="false"
            :users="users"
            @setUser="setUser"
            @getCustomerSelectUsers="getUsers"
          />
        </div>
      </div>
      <!-- End Users -->

      <!-- Products -->
      <div
        class="flex flex-col col-span-3 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
        v-if="coupon.type === 1"
      >
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-blue-200">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-Bold uppercase tracking-wider"
                  >
                    Products
                  </th>

                  <th scope="col" class="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody v-if="coupon.products && coupon.products.length > 0">
                <tr
                  v-for="(product, productIndex) in coupon.products"
                  :key="product._id"
                  :class="productIndex % 2 === 0 ? 'bg-white' : 'bg-blue-200'"
                >
                  <td class="p-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    <span class="flex items-center">
                      <img
                        v-if="product.image"
                        :src="product.image"
                        alt=""
                        class="flex-shrink-0 h-6 w-6 rounded-full"
                      />
                      <span class="ml-3 block truncate">{{ product.title }}</span>
                    </span>
                  </td>

                  <td
                    scope="col"
                    class="px-2 py-3 whitespace-nowrap text-right text-sm font-medium text-red"
                  >
                    <button
                      class="text-red hover:bg-red-200 rounded px-2 py-1"
                      @click="removeProduct(product._id)"
                    >
                      <font-awesome-icon icon="times" />
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="bg-white">
                  <td class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    No products selected
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-span-2" v-if="coupon.type === 1">
        <label class="block text-sm font-medium text-gray-700" for="productInput">
          Add Product
        </label>
        <form @submit.prevent="getProducts" class="mt-1 relative">
          <input
            autocomplete="new-password"
            type="text"
            name="product"
            id="productInput"
            v-model="productSearch"
            class="relative w-full bg-white border pl-3 pr-10 py-2 text-left cursor-default rounded-md focus:ring-1 focus:border-blue-500 sm:text-sm"
            @keydown.esc="productSearch = null"
          />

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              class="absolute mt-1 w-full bg-white shadow-lg max-h-56 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto border rounded-md sm:text-sm z-1"
              v-if="showProducts"
            >
              <ul v-if="products.length > 0">
                <li
                  v-for="product in products"
                  :key="product._id"
                  class="relative py-2 pl-3 pr-9 flex items-center hover:bg-blue-200 cursor-pointer"
                  @click="addProduct(product)"
                >
                  <img :src="product.image" alt="" class="flex-shrink-0 h-6 w-6 rounded-full" />

                  <span class="ml-3 block truncate">
                    {{ product.title }}
                  </span>
                </li>
              </ul>
              <ul v-else>
                <li
                  class="relative py-2 pl-3 pr-9 flex items-center hover:bg-blue-200 cursor-pointer"
                >
                  No products
                </li>
              </ul>
            </div>
          </transition>
        </form>
      </div>
      <!-- End Products -->

      <!-- Variants -->
      <div
        class="flex flex-col col-span-3 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 z-1"
        v-if="coupon.type === 2 || coupon.type === 7 || coupon.type === 8"
      >
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-blue-200">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-bold uppercase tracking-wider"
                  >
                    variants
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">
                      <font-awesome-icon icon="times" />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(variant, variantIndex) in coupon.variants"
                  :key="variant._id"
                  :class="variantIndex % 2 === 0 ? 'bg-white' : 'bg-blue-200'"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <span class="flex items-center">
                      <img
                        v-if="variant.thumbnail"
                        :src="variant.thumbnail"
                        alt=""
                        class="flex-shrink-0 h-6 w-6 rounded-full"
                      />
                      <span class="ml-3 block truncate">{{ variant.productNum }}</span>
                    </span>
                  </td>

                  <td
                    scope="col"
                    class="px-2 py-3 whitespace-nowrap text-right text-sm font-medium text-red"
                  >
                    <button
                      class="text-red hover:bg-red-200 rounded px-2 py-1"
                      @click="removeVariant(variant._id)"
                    >
                      <font-awesome-icon icon="times" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-span-2" v-if="coupon.type === 2 || coupon.type === 7 || coupon.type === 8">
        <label class="block text-sm font-medium text-gray-700" for="variantInput">
          Add Variant
        </label>
        <div class="mt-1 relative">
          <input
            autocomplete="new-password"
            type="text"
            id="variantInput"
            class="relative w-full bg-white border pl-3 pr-10 py-2 text-left cursor-default rounded-md focus:ring-1 focus:border-blue-500 sm:text-sm"
            @keydown.enter="variantSearch = $event.target.value"
            @keydown.esc="(variantSearch = null), ($event.target.value = null)"
          />

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              class="absolute mt-1 w-full bg-white shadow-lg max-h-56 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto border rounded-md sm:text-sm"
              v-if="showVariants"
            >
              <ul v-if="variants.length > 0" class="">
                <li
                  v-for="variant in variants"
                  :key="variant._id"
                  class="select-none relative py-2 pl-3 pr-9 flex items-center hover:bg-blue-200 cursor-pointer"
                  @click="addVariant(variant)"
                >
                  <img :src="variant.thumbnail" alt="" class="flex-shrink-0 h-6 w-6 rounded-full" />

                  <span class="ml-3 block truncate">
                    {{ variant.productNum }}
                  </span>
                </li>
              </ul>
              <ul v-else>
                <li
                  class="relative py-2 pl-3 pr-9 flex items-center hover:bg-blue-200 cursor-pointer"
                >
                  No variants
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
      <!-- End Variants -->
    </div>
    <div class="text-right my-4">
      <button class="text-red mx-4" @click="deleteCoupon">Delete</button>
      <button type="button" class="btn-primary rounded" @click="save">Save</button>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import CustomerSelect from '@/components/admin/shared/CustomerSelect';

export default {
  data: function () {
    return {
      selectedVariant: {},
      selectedProduct: {},
      productSearch: null,
      userKey: 0,
    };
  },
  props: {
    coupon: { type: Object, required: true },
  },
  computed: {
    showUsers() {
      return this.userSearch && this.userSearch.length > 0;
    },
    showVariants() {
      return this.variantSearch && this.variantSearch.length > 0;
    },
    showProducts() {
      return this.productSearch && this.productSearch.length > 0;
    },

    variantSearch: {
      get() {
        return this.$store.getters['variants/getSearch'];
      },
      set(val) {
        this.$store.commit('variants/setSearch', val);
      },
    },
    users: {
      get() {
        return this.$store.getters['users/getPrimaryUsers'];
      },
    },
    variants: {
      get() {
        return this.$store.getters['variants/getMoreVariants'](0);
      },
    },
    products: {
      get() {
        const products = this.$store.getters['products/getProducts'];
        return products.splice(0, 25);
      },
    },
  },
  watch: {},
  methods: {
    faultyInput(name) {
      if (name) {
        if (name.length > 3) {
          return false;
        }
      }
      return true;
    },
    setCouponDate() {
      if (this.coupon.expiration) {
        this.coupon.expiration = new Date(this.coupon.expiration);
        this.coupon.expiration = this.coupon.expiration.toISOString().split('T')[0].slice(0, 10);
      }
    },
    setUser(user) {
      this.userKey++;
      if (!this.coupon.users) {
        this.coupon.users = [];
      }
      if (
        !this.coupon.users.some((el) => {
          return el._id === user._id;
        })
      ) {
        this.coupon.users.push(user);
        this.userSearch = null;
        document.getElementById('userInput').value = null;
      }
    },

    removeUser(id) {
      this.userKey++;
      this.coupon.users = this.coupon.users.filter((el) => el._id !== id);
    },
    addProduct(product) {
      if (!this.coupon.products) {
        this.coupon.products = [];
      }
      if (!this.coupon.products.some((el) => el._id === product._id)) {
        this.coupon.products.push(product);
        this.productSearch = null;
        document.getElementById('productInput').value = null;
      }
    },
    removeProduct(id) {
      this.coupon.products = this.coupon.products.filter((el) => el._id !== id);
    },
    addVariant(variant) {
      if (!this.coupon.variants) {
        this.coupon.variants = [];
      }
      if (!this.coupon.variants.some((el) => el._id === variant._id)) {
        this.coupon.variants.push(variant);
        this.variantSearch = null;
        document.getElementById('variantInput').value = null;
      }
    },
    removeVariant(id) {
      this.coupon.variants = this.coupon.variants.filter((el) => el._id !== id);
    },
    deleteCoupon() {
      if (confirm('Are you sure you would like to delete this coupon?')) {
        this.$store.dispatch('admin/deleteCoupon', this.coupon._id);
        this.$emit('close');
      }
    },
    save() {
      if (this.coupon.code.length > 0 && this.coupon.expiration) {
        this.$store.dispatch('admin/saveCoupon', this.coupon);
      } else {
        this.$store.commit('setError', {
          color: 'red',
          message: 'You have to input an Expiration Date and a Code.',
        });
      }
    },
    getProducts() {
      this.$store.dispatch('products/search', `title=${this.productSearch}`);
    },
    getUsers(value) {
      this.$store.dispatch('users/getPrimaryUsers', `&search=${value}`);
    },
  },
  mounted() {
    this.setCouponDate();
    this.$store.dispatch('users/getPrimaryUsers', '');
  },
  components: { ToggleButton, CustomerSelect },
};
</script>
<style scoped></style>
